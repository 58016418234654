import React, { useState } from "react";
import { Auth, withNavigation, Toast, useToast } from "react-uicomp";
import {
  getCookie,
  removeCookie,
  setCookie,
  encrypt,
  decrypter,
} from "../../helpers/Cookie.helper";
import { publicPaths, privatePaths } from "./routes";
import { userRoles } from "./userRoles";

import SideNavigation from "../common/sideNavigation/SideNavigation.common";

const SIGN_TIME = Date.now();

const App = () => {
  const { handler, toast } = useToast();

  const enLogged = getCookie("isLoggedin");
  const tCheck = getCookie("gTime");

  const isLoggedIn =
    enLogged && tCheck
      ? decrypter(enLogged, tCheck) === "true"
        ? true
        : false
      : false;

  const [config, setConfig] = useState({
    isLoggedIn,
    userRole: "admin",
  });

  const [sideNavVisible, setSideNavVisible] = useState(false);

  return (
    <Auth.Provider
      config={config}
      state={{
        toast,
        setSideNavVisible,
        handleLogin: (token) => {
          // localStorage.setItem("isLoggedin", true);
          // localStorage.setItem("token", token);

          setCookie("gTime", SIGN_TIME);
          setCookie("isLoggedin", encrypt("true", SIGN_TIME));
          setCookie("token", encrypt(token, SIGN_TIME));

          setConfig({
            isLoggedIn: true,
            userRole: "admin",
          });
        },
        handleLogout: () => {
          // localStorage.removeItem("isLoggedin");
          // localStorage.removeItem("token");

          removeCookie("isLoggedin");
          removeCookie("token");
          removeCookie("gTime");

          setConfig({
            isLoggedIn: false,
            userRole: "admin",
          });
        },
      }}>
      <Auth.Screens />
      <SideNavigation
        mobileSideNavVisible={sideNavVisible}
        setMobileSideNavVisible={setSideNavVisible}
      />
      <Toast {...handler} errorColor="#ff4343" style={{ minWidth: 300 }} />
    </Auth.Provider>
  );
};

export default withNavigation(App, {
  publicPaths,
  privatePaths,
  userRoles,
  routerType: "hash",
});
