import {
  EVENT,
  ADD_EVENT,
  DELETE_EVENT,
  EVENT_DETAIL,
  EDIT_EVENT,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getEventAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EVENT.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.events}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(`${APIS.events}&page=${page}&limit=${TABLE_LIMIT}`);
      }

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EVENT.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: EVENT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EVENT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getEventDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EVENT_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: EVENT_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: EVENT_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EVENT_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postEventAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_EVENT.LOADING });
      res = await api(APIS.commonResearch, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_EVENT.SUCCESS });
        toast({ message: "Event successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_EVENT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_EVENT.ERROR });
      console.error(message);
      toast({ message: "Error Adding Event", type: "error" });
      return 0;
    }
  };
}

export function editEventAction(id, body, photoFormdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_EVENT.LOADING });
      res = await api(`${APIS.commonResearch}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.common}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;
        if (photoSuccess === "true") {
          dispatch({ type: EDIT_EVENT.SUCCESS });
          toast({ message: "Event successfully edited", type: "success" });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_EVENT.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_EVENT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_EVENT.ERROR });
      console.error(message);
      toast({ message: "Error editing Event", type: "error" });
      return 0;
    }
  };
}

export function deleteEventAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_EVENT.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_EVENT.SUCCESS });
        toast({ message: "Event successfully deleted", type: "success" });
        dispatch(getEventAction(page));
      } else {
        dispatch({ type: DELETE_EVENT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_EVENT.ERROR });
      toast({ message: "Error Deleting Event", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
