import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import Select from "react-select";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postNewletterAction,
  getResearchProgramAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";
import { InvertedButton } from "../../common/button/Button.common";

// HELPERS
import { validator, isValid } from "../../../utils/Validator.util";

const AddNewsLetterPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const {
    postNewletterAction,
    newsLetter,
    getResearchProgramAction,
    researchProgram,
  } = props;
  const { addLoader } = newsLetter;
  const { listResearchPorgram } = researchProgram;
  const { handleSubmit, register } = useForm();

  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");

  const onChange = (imageList) => {
    setImageError(false);
    setImage(imageList);
  };

  const onChangeHandler = (event) => {
    if (event.target.files.length) {
      setFileName(event.target.files[0].name);
      setFile(event.target.files);
    }
  };

  const [researchProgramOption, setResearchProgramOption] = useState([]);
  const [selectedResearchProgram, setSelectedResearchProgram] = useState([]);

  const handleResearchProgram = (selectedOptions) => {
    setSelectedResearchProgram(selectedOptions);
  };

  useEffect(() => {
    const remappedResearchProgramList = listResearchPorgram?.map(
      (item, index) => {
        return {
          id: item.id,
          label: item.name.en,
          value: item.name.en,
        };
      },
    );
    setResearchProgramOption(remappedResearchProgramList);
  }, [listResearchPorgram]);

  useEffect(() => {
    getResearchProgramAction(1);
  }, [getResearchProgramAction]);

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);

    // VALIDATION
    validate("imageFile", image?.length === 0, () => {
      toast({ message: "Image is Required", type: "error" });
    });

    validate("pdfFile", file?.length === 0, () => {
      toast({ message: "Pdf file is Required", type: "error" });
    });

    validate(
      "selectedResearchProgram",
      selectedResearchProgram?.length === 0,
      () => {
        toast({
          message: "Please Choose at least one research program",
          type: "error",
        });
      },
    );

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const description = {
      title: {
        en: data.titleEng,
        np: data.titleNep,
      },
      shortDesc: {
        en: data.shortDescriptionEng,
        np: data.shortDescriptionNep,
      },
      date: {
        en: "Apr 25, 2019",
        np: "Apr 25, 2019",
      },
    };

    const formdata = new FormData();
    const remappedSelectedResearchProgram = selectedResearchProgram?.map(
      (item, index) => {
        return item.id;
      },
    );

    formdata.append("title", data.titleEng);
    formdata.append("description", JSON.stringify(description));
    formdata.append("type", "newsletter");
    formdata.append(
      "researchProgramId",
      JSON.stringify(remappedSelectedResearchProgram),
    );
    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("imageFile", element.file);
      });
    file?.length > 0 && formdata.append("pdfFile", file[0]);

    postNewletterAction(formdata, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes.NewsLetter.path);
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Title</div>

              <InputField
                name="titleEng"
                placeholder="Title"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Short Description</div>

              <TextAreaField
                name="shortDescriptionEng"
                placeholder="Short Description ..."
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">शीर्षक</div>

              <InputField
                name="titleNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">छोटो वर्णन</div>

              <TextAreaField
                name="shortDescriptionNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-files">
              <div className="fileupload">
                <input type="file" name="file" onChange={onChangeHandler} />
                <div style={{ width: 300 }}>
                  <InvertedButton
                    onClick={(event) => event.preventDefault()}
                    title="Choose file"
                  />
                </div>
              </div>

              <div>{fileName}</div>
            </div>

            <ImageUploading value={image} onChange={onChange} multiple={true}>
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                          overflow: "hidden",
                        }}>
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                        <AiFillCloseCircle
                          onClick={() => onImageRemove(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: 20,
                            width: 20,
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    className="fixedwidth"
                    onClick={onImageUpload}
                    variant="contained"
                    color="primary">
                    {image?.length > 0 ? "Change Image" : "Upload image"}
                  </Button>
                  {imageError && <div>Image Required</div>}
                </div>
              )}
            </ImageUploading>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Research Program</div>
              <Select
                isMulti
                options={researchProgramOption}
                value={selectedResearchProgram}
                onChange={handleResearchProgram}
                placeholder="Select Research Program"
              />
            </div>

            <ActivityIndicator animating={addLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    newsLetter: state.newsLetter,
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postNewletterAction,
      getResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewsLetterPage);
