import {
  STUDY,
  ADD_STUDY,
  DELETE_STUDY,
  EDIT_STUDY,
  STUDY_DETAIL,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getStudyAction(page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: STUDY.LOADING });
      res = await api(`${APIS.study}&page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: STUDY.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: STUDY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: STUDY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getStudyDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: STUDY_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: STUDY_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: STUDY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: STUDY_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postStudyAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_STUDY.LOADING });
      res = await api(APIS.common, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_STUDY.SUCCESS });
        toast({ message: "Study successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_STUDY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_STUDY.ERROR });
      console.error(message);
      toast({ message: "Error Adding Study", type: "error" });
      return 0;
    }
  };
}

export function editStudyAction(id, body, photoFormdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_STUDY.LOADING });
      res = await api(`${APIS.common}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.common}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;

        if (photoSuccess === "true") {
          dispatch({ type: EDIT_STUDY.SUCCESS });
          toast({
            message: "Study successfully edited",
            type: "success",
          });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_STUDY.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_STUDY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_STUDY.ERROR });
      console.error(message);
      toast({ message: "Error editing Study", type: "error" });
      return 0;
    }
  };
}

export function deleteStudyAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_STUDY.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_STUDY.SUCCESS });
        toast({ message: "Study successfully deleted", type: "success" });
        dispatch(getStudyAction(page));
      } else {
        dispatch({ type: DELETE_STUDY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_STUDY.ERROR });
      toast({ message: "Error Deleting Study", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
