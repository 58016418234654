import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { AiFillCloseCircle } from "react-icons/ai";

import Select from "react-select";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editStudyAction,
  getResearchAction,
  getStudyDetailAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";
import DotPulse from "../../common/dotPulse/DotPulse.common";
import { FILE_URL } from "../../../config/Config";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 4,
    borderColor: "#E1E1E1",
    backgroundColor: "#F8F8F8",
    "&:hover": { borderColor: "#0284A4" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284A4" : "#fff",
      "&:hover": { backgroundColor: "#e9e9e9" },
    };
  },
};

const EditStudyPage = (props) => {
  const { navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const {
    editStudyAction,
    getResearchAction,
    getStudyDetailAction,
    study,
    research,
  } = props;
  const { detail, editLoader } = study;
  const { list } = research;
  const { handleSubmit, register, control } = useForm();

  const [defaultImage, setDetfaultImage] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);
  const [image, setImage] = useState([]);

  useEffect(() => {
    getResearchAction();
  }, [getResearchAction]);

  useEffect(() => {
    getStudyDetailAction(Number(params.id));
  }, [getStudyDetailAction, params.id]);

  useEffect(() => {
    if (!!detail) {
      setDetfaultImage(detail.images);
    }
  }, [detail]);

  const onChange = (imageList) => {
    setImage(imageList);
  };

  const defaultImageRemove = (index) => {
    const del = [...deletedImage];
    if (!del.includes(index)) {
      del.push(index);
      setDeletedImage(del);
    }
  };

  const onSubmit = (data) => {
    const description = {
      research: detail.description.research,
      study: {
        en: data.studyEng,
        np: data.studyNep,
      },
      name: {
        en: data.nameEng,
        np: data.nameNep,
      },
      title: {
        en: data.titleEng,
        np: data.titleNep,
      },
      background: {
        en: data.backgroundEng,
        np: data.backgroundNep,
      },
      aim: {
        en: data.aimEng,
        np: data.aimNep,
      },
      method: {
        en: data.methodEng,
        np: data.methodNep,
      },
      impact: {
        en: data.impactEng,
        np: data.impactNep,
      },
      team: {
        en: data.teamEng,
        np: data.teamNep,
      },
      link: data.linkEng,
    };

    if (!!data.reasearch?.cat_description) {
      description["research"] = {
        en: JSON.parse(data.research.cat_description).title.en,
        np: JSON.parse(data.research.cat_description).title.np,
      };
    }

    const body = {
      description: JSON.stringify(description),
      type: "research",
    };

    if (!!data.reasearch?.cat_description) {
      body["category"] = data.research.id;
    }

    const photoFormdata = new FormData();
    photoFormdata.append("type", "research");
    deletedImage.length > 0 &&
      photoFormdata.append("removeIndex", JSON.stringify(deletedImage));

    image.length > 0 &&
      image.forEach((element) => {
        photoFormdata.append("imageFile", element.file);
      });

    editStudyAction(
      Number(params.id),
      body,
      photoFormdata,
      goBackHandler,
      toast,
    );
  };

  const goBackHandler = () => {
    navigate(routes.Study.path);
  };

  return (
    <CompWrapper>
      {!detail ? (
        <DotPulse />
      ) : (
        <div className="addnews-container">
          <div className="addnews">
            <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Research</div>
                {list.length > 0 && (
                  <Controller
                    as={
                      <Select
                        isClearable={true}
                        getOptionValue={(option) => `${option.id}`}
                        getOptionLabel={(option) => `${option.cat_name}`}
                        options={list}
                        styles={selectStyles}
                        placeholder="Select Research"
                      />
                    }
                    name="research"
                    control={control}
                    // defaultValue=""
                    defaultValue={list.filter(
                      (obj) => obj.id === detail?.cat_id,
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                  />
                )}
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Study Name</div>

                <InputField
                  name="studyEng"
                  innerRef={register}
                  defaultValue={detail?.description?.study?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Name</div>

                <InputField
                  name="nameEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.name?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Project Title</div>

                <InputField
                  name="titleEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.title?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Background</div>

                <TextAreaField
                  name="backgroundEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.background.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Project Aim</div>

                <TextAreaField
                  name="aimEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.aim.en}
                />
              </div>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Methods</div>

                <TextAreaField
                  name="methodEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.method.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">
                  Anticipated Impact
                </div>

                <TextAreaField
                  name="impactEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.impact.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Study Team</div>

                <TextAreaField
                  name="teamEng"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.team.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">
                  Full text article available at
                </div>

                <TextAreaField
                  name="linkEng"
                  innerRef={register}
                  defaultValue={detail?.description?.link}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">अध्ययन नाम</div>

                <InputField
                  name="studyNep"
                  innerRef={register}
                  defaultValue={detail?.description?.study?.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">नाम</div>

                <InputField
                  name="nameNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.name?.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">परियोजना शिर्षक</div>

                <InputField
                  name="titleNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.title.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">पृष्ठभूमि</div>

                <TextAreaField
                  name="backgroundNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.background.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">
                  परियोजनाको उद्धेश्य
                </div>

                <TextAreaField
                  name="aimNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.aim.np}
                />
              </div>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">विधीहरु</div>

                <TextAreaField
                  name="methodNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.method.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">अपेक्षित प्रभाव</div>

                <TextAreaField
                  name="impactNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.impact.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">अध्ययन टोली</div>

                <TextAreaField
                  name="teamNep"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.team.np}
                />
              </div>

              <ImageUploading value={image} onChange={onChange} multiple={true}>
                {({ imageList, onImageUpload, onImageRemove }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}>
                      {defaultImage.map(
                        (image, index) =>
                          !deletedImage.includes(index) && (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                width: 200,
                                height: 200,
                                overflow: "hidden",
                              }}>
                              <img
                                src={FILE_URL + image}
                                alt=""
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                  overflow: "hidden",
                                }}
                              />
                              <AiFillCloseCircle
                                onClick={() => defaultImageRemove(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  height: 20,
                                  width: 20,
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ),
                      )}

                      {imageList.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            width: 200,
                            height: 200,
                            overflow: "hidden",
                          }}>
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                          <AiFillCloseCircle
                            onClick={() => onImageRemove(index)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              height: 20,
                              width: 20,
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      className="fixedwidth"
                      onClick={onImageUpload}
                      variant="contained"
                      color="primary">
                      {image?.length > 0 ? "Change Image" : "Upload image"}
                    </Button>
                  </div>
                )}
              </ImageUploading>
              {/* <div className="addnews-form-input">
                <div className="addnews-form-input-label">
                  अध्ययनको विस्तृत विवरण यहाँ उपलब्ध छ
                </div>

                <TextAreaField
                  name="linkNep"
                  innerRef={register}
                  defaultValue={detail?.description?.link?.np}
                />
              </div> */}

              <ActivityIndicator animating={editLoader}>
                <Button
                  className="fixedwidth"
                  variant="contained"
                  color="primary"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    study: state.study,
    research: state.research,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editStudyAction,
      getResearchAction,
      getStudyDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudyPage);
