import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVacancyAction, deleteVacancyAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

const VacancyPage = (props) => {
  const { getVacancyAction, deleteVacancyAction, vacancy } = props;
  const { listLoader, listCount, list, deleteLoader } = vacancy;
  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  // const isPassed = isDeadlinePassed(deadline);

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    getVacancyAction(pageNumber);
  }, [getVacancyAction, pageNumber]);

  const deleteHandler = (id) => {
    deleteVacancyAction(id, toast, pageNumber);
  };

  const editHandler = (id) => {
    navigate(`vacancy/edit/${id}`);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Vacancy</div>
            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Vacancy"].path);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "title",
                },
                {
                  field: "description",
                  name: "Deadline",
                  render: (rowData) => {
                    const currentDate = new Date();
                    if (currentDate > new Date(rowData.expirationDate)) {
                      return <p className="chip-old">Closed</p>;
                    } else {
                      return <p className="chip-new">Open</p>;
                    }
                  },
                },
              ]}
              data={list}
              actions
              dataLoader={listLoader}
              totalCount={listCount}
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVacancyAction,
      deleteVacancyAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyPage);
