import {
    RESEARCHPAPER, ADD_RESEARCHPAPER
} from "../actions/Actions";

const initalState = {
    loading: false,
    error: false,

    researchPaperListLoader: false,
    researchPaperListError: false,
    researchPaperListSuccess: false,
    researchPaperListCount: null,
    researchPaperList: [],

    addResearchPaperLoader: false,
    addResearchPaperError: false,
    addResearchPaperSuccess: false,
};

export function researchPaperReducer(state = initalState, action) {
    const { type, payload } = action;

    switch (type) {
        case RESEARCHPAPER.LOADING:
            return { ...state, researchPaperListLoader: true, researchPaperListError: false, researchPaperListSuccess: false, };
        case RESEARCHPAPER.SUCCESS:
            return { ...state, researchPaperListLoader: false, researchPaperListError: false, researchPaperListSuccess: true, researchPaperListCount: payload.totalcount, researchPaperList: payload.data };
        case RESEARCHPAPER.ERROR:
            return { ...state, researchPaperListLoader: false, researchPaperListError: true, researchPaperListSuccess: false, };

        case ADD_RESEARCHPAPER.LOADING:
            return { ...state, addResearchPaperLoader: true, addResearchPaperError: false, addResearchPaperSuccess: false, };
        case ADD_RESEARCHPAPER.SUCCESS:
            return { ...state, addResearchPaperLoader: false, addResearchPaperError: false, addResearchPaperSuccess: true, };
        case ADD_RESEARCHPAPER.ERROR:
            return { ...state, addResearchPaperLoader: false, addResearchPaperError: true, addResearchPaperSuccess: false, };

        default:
            return state;
    }
}