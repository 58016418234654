import {
  VACANCY,
  ADD_VACANCY,
  VACANCY_DETAIL,
  DELETE_VACANCY,
  EDIT_VACANCY,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  detailLoader: false,
  detail: null,
  editLoader: false,
};

export function vacancyReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case VACANCY.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case VACANCY.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case VACANCY.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    //  ADD
    case ADD_VACANCY.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_VACANCY.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_VACANCY.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    // GET DETAIL
    case VACANCY_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case VACANCY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case VACANCY_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    //  edit
    case EDIT_VACANCY.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_VACANCY.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_VACANCY.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // DELETE
    case DELETE_VACANCY.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_VACANCY.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_VACANCY.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    default:
      return state;
  }
}
