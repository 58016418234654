import React from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postResearchProgramAction } from "../../../actions/ResearchProgram.action";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import { InputField } from "../../common/inputField/InputField.common";

// HELPERS
import { isValid } from "../../../utils/Validator.util";

// const selectStyles = {
//   control: (styles) => ({
//     ...styles,
//     borderRadius: 4,
//     borderColor: "#E1E1E1",
//     backgroundColor: "#F8F8F8",
//     "&:hover": { borderColor: "#0284A4" },
//   }),
//   option: (styles, { isSelected }) => {
//     return {
//       ...styles,
//       backgroundColor: isSelected ? "#0284A4" : "#fff",
//       "&:hover": { backgroundColor: "#e9e9e9" },
//     };
//   },
// };

const AddResearchProgramPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { postResearchProgramAction, researchProgram } = props;
  const { addResearchProgramLoader } = researchProgram;
  const { handleSubmit, register } = useForm();

  //   const [imageError, setImageError] = useState(false);

  // const statusOptions = [
  //   {
  //     id: 1,
  //     label: "Old",
  //     value: "old",
  //   },
  //   {
  //     id: 1,
  //     label: "New",
  //     value: "new",
  //   },
  // ];

  const onSubmit = (data) => {
    const catchedErros = {};

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const researchProgramdata = {
      name: {
        en: data.titleEng,
        np: data.titleNep,
      },
      // status: data.researchProgram.value,
    };
    postResearchProgramAction(researchProgramdata, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes["Research Program"].path);
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Title</div>

              <InputField
                name="titleEng"
                placeholder="Title"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">शीर्षक</div>

              <InputField
                name="titleNep"
                placeholder="शीर्षक"
                innerRef={register({ required: true })}
              />
            </div>

            {/* <div className="addnews-form-input-label">Research</div> */}

            {/* <Controller
              as={
                <Select
                  isClearable={true}
                  getOptionValue={(option) => `${option.value}`}
                  getOptionLabel={(option) => `${option.label}`}
                  options={statusOptions}
                  styles={selectStyles}
                  placeholder="Select Status"
                />
              }
              name="researchProgram"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: "This field is required" },
              }}
            /> */}

            <ActivityIndicator animating={addResearchProgramLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddResearchProgramPage);
