import {
    CONFERENCEPRESENTATION, ADD_CONFERENCEPRESENTATION
} from "../actions/Actions";

const initalState = {
    loading: false,
    error: false,

    conferencePresentationListLoader: false,
    conferencePresentationListError: false,
    conferencePresentationListSuccess: false,
    conferencePresentationListCount: null,
    conferencePresentationList: [],

    addConferencePresentationLoader: false,
    addConferencePresentationError: false,
    addConferencePresentationSuccess: false,

};

export function conferencePresentationReducer(state = initalState, action) {
    const { type, payload } = action;

    switch (type) {
        case CONFERENCEPRESENTATION.LOADING:
            return { ...state, conferencePresentationListLoader: true, conferencePresentationListError: false, conferencePresentationListSuccess: false, };
        case CONFERENCEPRESENTATION.SUCCESS:
            return { ...state, conferencePresentationListLoader: false, conferencePresentationListError: false, conferencePresentationListSuccess: true, conferencePresentationListCount: payload.totalcount, conferencePresentationList: payload.data };
        case CONFERENCEPRESENTATION.ERROR:
            return { ...state, conferencePresentationListLoader: false, conferencePresentationListError: true, conferencePresentationListSuccess: false, };

        case ADD_CONFERENCEPRESENTATION.LOADING:
            return { ...state, addConferencePresentationLoader: true, addConferencePresentationError: false, addConferencePresentationSuccess: false, };
        case ADD_CONFERENCEPRESENTATION.SUCCESS:
            return { ...state, addConferencePresentationLoader: false, addConferencePresentationError: false, addConferencePresentationSuccess: true, };
        case ADD_CONFERENCEPRESENTATION.ERROR:
            return { ...state, addConferencePresentationLoader: false, addConferencePresentationError: true, addConferencePresentationSuccess: false, };

        default:
            return state;
    }
}