import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
// import { useForm } from "react-hook-form";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getResearchProgramAction,
  deleteResearchProgramAction,
} from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// const selectStyles = {
//   control: (styles) => ({
//     ...styles,
//     borderRadius: 4,
//     borderColor: "#E1E1E1",
//     backgroundColor: "#F8F8F8",
//     "&:hover": { borderColor: "#0284A4" },
//   }),
//   option: (styles, { isSelected }) => {
//     return {
//       ...styles,
//       backgroundColor: isSelected ? "#0284A4" : "#fff",
//       "&:hover": { backgroundColor: "#e9e9e9" },
//     };
//   },
// };

const ResearchProgramPage = (props) => {
  const {
    getResearchProgramAction,
    deleteResearchProgramAction,
    researchProgram,
  } = props;
  const {
    listResearchProgramLoader,
    listResearchProgramCount,
    listResearchPorgram,
    deleteResearchProgramLoader,
  } = researchProgram;
  const { location, navigation } = useNavigation();
  // const { control, watch, register } = useForm();
  // const [selectedStatus, setSelectedStatus] = useState([]);
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    getResearchProgramAction(pageNumber, true);
  }, [getResearchProgramAction, pageNumber]);

  // useEffect(() => {
  //   getResearchProgramAction(pageNumber, watch("researchProgram")?.value);
  // }, [watch("researchProgram"), pageNumber, getResearchProgramAction]);

  const deleteHandler = (id) => {
    deleteResearchProgramAction(id, toast, pageNumber);
  };

  const editHandler = (id) => {
    navigate(`research-program/edit/${id}`);
  };

  // const capitalizeFirst = (str) => {
  //   return str?.charAt(0).toUpperCase() + str?.slice(1);
  // };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Research Program</div>
            {/* <Controller
              as={
                <Select
                  isClearable={true}
                  getOptionValue={(option) => `${option.value}`}
                  getOptionLabel={(option) => `${option.label}`}
                  options={statusOptions}
                  styles={selectStyles}
                  {...register("researchProgram")}
                  placeholder="All"
                  style={{ width: "300px" }}
                />
              }
              name="researchProgram"
              control={control}
              rules={{
                required: { value: true, message: "This field is required" },
              }}
            /> */}
            {/* 
            <Select
              isMulti={false}
              options={statusOptions}
              value={selectedStatus}
              onChange={handleStatus}
              placeholder="All"
            /> */}

            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Research Program"].path);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "name",
                  name: "title",
                  render: (rowData) => {
                    return <p>{rowData?.en}</p>;
                  },
                },
                // {
                //   field: "status",
                //   name: "Status",
                //   render: (rowData) => {
                //     return (
                //       <p
                //         className={rowData === "old" ? "chip-old" : "chip-new"}>
                //         {capitalizeFirst(rowData)}
                //       </p>
                //     );
                //   },
                // },
              ]}
              data={listResearchPorgram}
              actions
              dataLoader={listResearchProgramLoader}
              totalCount={listResearchProgramCount}
              deleteLoader={deleteResearchProgramLoader}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getResearchProgramAction,
      deleteResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResearchProgramPage);
