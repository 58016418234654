import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getStudyAction,
  deleteStudyAction,
  getResearchProgramAction,
} from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

const StudyPage = (props) => {
  const { getStudyAction, deleteStudyAction, study } = props;
  const { listLoader, listCount, list, deleteLoader } = study;

  const { location, navigation } = useNavigation();
  const { toast } = useAuth();

  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    getStudyAction(pageNumber);
  }, [getStudyAction, pageNumber]);

  const deleteHandler = (id) => {
    deleteStudyAction(id, toast, pageNumber);
  };

  const editHandler = (id) => {
    navigate(`study/edit/${id}`);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Study</div>

            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Study"].path);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "cat_name",
                  name: "research",
                },
                {
                  field: "description",
                  name: "title",
                  render: (rowData) => <p>{rowData?.title?.en}</p>,
                },
                {
                  field: "description",
                  name: "method",
                  render: (rowData) => <p>{rowData?.method?.en}</p>,
                },
                {
                  field: "description",
                  name: "team",
                  render: (rowData) => <p>{rowData?.team?.en}</p>,
                },
              ]}
              data={list}
              actions
              dataLoader={listLoader}
              totalCount={listCount}
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    study: state.study,
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStudyAction,
      deleteStudyAction,
      getResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyPage);
