import {
  MEDIAOUTPUT,
  MEDIAOUTPUT_DETAIL,
  ADD_MEDIAOUTPUT,
  EDIT_MEDIAOUTPUT,
  DELETE_MEDIAOUTPUT,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  editLoader: false,
  detailLoader: false,
  detail: null,
};

export function mediaOutputReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case MEDIAOUTPUT.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case MEDIAOUTPUT.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case MEDIAOUTPUT.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // MEDIAOUTPUT_DETAIL
    case MEDIAOUTPUT_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case MEDIAOUTPUT_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case MEDIAOUTPUT_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    case ADD_MEDIAOUTPUT.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_MEDIAOUTPUT.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_MEDIAOUTPUT.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    // EDIT_MEDIAOUTPUT
    case EDIT_MEDIAOUTPUT.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_MEDIAOUTPUT.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_MEDIAOUTPUT.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    case DELETE_MEDIAOUTPUT.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_MEDIAOUTPUT.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_MEDIAOUTPUT.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    default:
      return state;
  }
}
