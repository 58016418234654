import {
  MEDIAOUTPUT,
  ADD_MEDIAOUTPUT,
  EDIT_MEDIAOUTPUT,
  DELETE_MEDIAOUTPUT,
  MEDIAOUTPUT_DETAIL,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getMediaOutputAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: MEDIAOUTPUT.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.media}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(`${APIS.media}&page=${page}&limit=${TABLE_LIMIT}`);
      }

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: MEDIAOUTPUT.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: MEDIAOUTPUT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: MEDIAOUTPUT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getMediaOutputDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: MEDIAOUTPUT_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: MEDIAOUTPUT_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: MEDIAOUTPUT_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: MEDIAOUTPUT_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postMediaOutputAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_MEDIAOUTPUT.LOADING });
      res = await api(APIS.commonResearch, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_MEDIAOUTPUT.SUCCESS });
        toast({ message: "Presentation successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_MEDIAOUTPUT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_MEDIAOUTPUT.ERROR });
      console.error(message);
      toast({ message: "Error Adding Presentation", type: "error" });
      return 0;
    }
  };
}

export function editMediaOutputAction(
  id,
  body,
  photoFormdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_MEDIAOUTPUT.LOADING });
      res = await api(`${APIS.commonResearch}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.common}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;
        if (photoSuccess === "true") {
          dispatch({ type: EDIT_MEDIAOUTPUT.SUCCESS });
          toast({
            message: "Presentation successfully edited",
            type: "success",
          });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_MEDIAOUTPUT.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_MEDIAOUTPUT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_MEDIAOUTPUT.ERROR });
      console.error(message);
      toast({ message: "Error editing Presentation", type: "error" });
      return 0;
    }
  };
}

export function deleteMediaOutputAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_MEDIAOUTPUT.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_MEDIAOUTPUT.SUCCESS });
        toast({
          message: "Presentation successfully deleted",
          type: "success",
        });
        dispatch(getMediaOutputAction(page));
      } else {
        dispatch({ type: DELETE_MEDIAOUTPUT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_MEDIAOUTPUT.ERROR });
      toast({ message: "Error Deleting Presentation", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
