import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContactAction, deleteContactAction } from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import Table from "../common/table/Table.common";

// HELPER

const ContactPage = (props) => {
  const { getContactAction, deleteContactAction, contact } = props;
  const { listLoader, listCount, list, deleteLoader } = contact;
  const { location } = useNavigation();
  const { toast } = useAuth();
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    getContactAction(pageNumber);
  }, [getContactAction, pageNumber]);

  const deleteHandler = (id) => {
    deleteContactAction(id, toast, pageNumber);
  };

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Contact</div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "description",
                  name: "name",
                  render: (rowData) => <p>{rowData?.name}</p>,
                },
                {
                  field: "description",
                  name: "phone",
                  render: (rowData) => <p>{rowData?.phone}</p>,
                },
                {
                  field: "description",
                  name: "email",
                  render: (rowData) => <p>{rowData?.email}</p>,
                },
                {
                  field: "description",
                  name: "subject",
                  render: (rowData) => <p>{rowData?.subject}</p>,
                },
                {
                  field: "description",
                  name: "message",
                  render: (rowData) => <p>{rowData?.message}</p>,
                },
              ]}
              data={list}
              actions
              dataLoader={listLoader}
              totalCount={listCount}
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactAction,
      deleteContactAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
