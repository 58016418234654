import { CONTACT, DELETE_CONTACT } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
};

export function contactReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case CONTACT.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case CONTACT.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case CONTACT.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // DELETE
    case DELETE_CONTACT.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_CONTACT.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_CONTACT.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };
    default:
      return state;
  }
}
