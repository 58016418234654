import {
SIGNIN
} from "../actions/Actions";

const initalState = {
loading:false,
error:false
};

export function signinReducer(state = initalState, action) {
    const { type } = action;

    switch(type) {
        case SIGNIN.LOADING:
            return {...state, loading: true, error:false};
        case SIGNIN.SUCCESS:
            return {...state, loading: false, error:false};
        case SIGNIN.ERROR:
            return {...state, loading: false, error:true};
        default:
            return state;
            }
          }