import { apiGenerator } from "../helpers/Helpers";

// const token = getCookie("token"); // GET TOKEN FROM COOKIE
// const token = localStorage.getItem("token");
export const BASE_URL = "https://api.nirc.org.np/api"; // BASE URL
// export const BASE_URL = "http://192.168.1.114:8849/api"; // BASE URL

export const FILE_URL = "https://api.nirc.org.np/docs/"; // BASE FILE URL
// export const FILE_URL = "http://192.168.1.114:8849/docs/"; // BASE FILE URL

export const TABLE_LIMIT = 5;
export const SELECT_FIELD_LIMIT = 1000;

// API FUNCTION
export const api = apiGenerator({ baseURL: BASE_URL });

// API PATHS
export const APIS = {
  sample: "/sample",
  login: "/auth/login",
  password: "/user/pass",
  category_all: "/category/all",
  category: "/category",
  researchCategory: "/research-category",
  researchProgram: "/research-program",
  products_all: "/products/all",
  products: "/products",
  common: "/common",
  commonResearch: "/common-research",
  createTeam: "/research-team",

  events: "/common/type/dashboard?name=events",
  media: "/common/type/dashboard?name=media",
  news: "/common/type/dashboard?name=news",
  publication: "/common/type/dashboard?name=publications",
  team: "/team",
  researchTeams: "/team/type/dashboard?name=team",
  newsLetter: "/common/type/dashboard?name=newsletter",
  partner: "/team/type/dashboard?name=partner",
  vacancy: "/common/type?name=vacancy",
  study: "/common/type?name=research",
  contact: "/common/type?name=contact",
};
