import React, { useEffect } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editResearchProgramAction,
  getResearchProgramDetailAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import { InputField } from "../../common/inputField/InputField.common";
import DotPulse from "../../common/dotPulse/DotPulse.common";

// HELPERS
import { isValid } from "../../../utils/Validator.util";

const EditResearchProgramPage = (props) => {
  const { navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const {
    editResearchProgramAction,
    getResearchProgramDetailAction,
    researchProgram,
  } = props;

  const { editResearchProgramLoader, researchProgramdetail } = researchProgram;
  const { handleSubmit, register } = useForm();

  // const statusOptions = [
  //   {
  //     id: 1,
  //     label: "Old",
  //     value: "old",
  //   },
  //   {
  //     id: 2,
  //     label: "New",
  //     value: "new",
  //   },
  // ];

  const goBackHandler = () => {
    navigate(routes["Research Program"].path);
  };

  useEffect(() => {
    getResearchProgramDetailAction(Number(params.id));
  }, [getResearchProgramDetailAction, params.id]);

  const onSubmit = (data) => {
    const catchedErros = {};

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const updateResearchProgramdata = {
      name: {
        en: data.titleEng,
        np: data.titleNep,
      },
      // status: data.researchProgram.value,
    };

    editResearchProgramAction(
      Number(params.id),
      updateResearchProgramdata,
      goBackHandler,
      toast,
    );
  };

  // console.log("researchProgramdetail", researchProgramdetail?.status);

  return (
    <CompWrapper>
      {!researchProgramdetail ? (
        <DotPulse />
      ) : (
        <div className="addnews-container">
          <div className="addnews">
            <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Title</div>

                <InputField
                  name="titleEng"
                  placeholder="Title"
                  innerRef={register({ required: true })}
                  defaultValue={researchProgramdetail?.name.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">शीर्षक</div>

                <InputField
                  name="titleNep"
                  placeholder="शीर्षक"
                  innerRef={register({ required: true })}
                  defaultValue={researchProgramdetail?.name?.np}
                />
              </div>

              {/* <div className="addnews-form-input-label">Research</div> */}
              {/* <Controller
                as={
                  <Select
                    isClearable={true}
                    getOptionValue={(option) => `${option.value}`}
                    getOptionLabel={(option) => `${option.label}`}
                    options={statusOptions}
                    styles={selectStyles}
                    placeholder="Select Status"
                    defaultValue={{
                      id: 1,
                      label: "old",
                      value: "old",
                    }}
                  />
                }
                name="researchProgram"
                control={control}
                defaultValue={
                  researchProgramdetail?.status === "old"
                    ? {
                        id: 1,
                        label: "Old",
                        value: "old",
                      }
                    : {
                        id: 2,
                        label: "New",
                        value: "new",
                      }
                }
                rules={{
                  required: { value: true, message: "This field is required" },
                }}
              /> */}

              <ActivityIndicator animating={editResearchProgramLoader}>
                <Button
                  className="fixedwidth"
                  variant="contained"
                  color="primary"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editResearchProgramAction,
      getResearchProgramDetailAction,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditResearchProgramPage);
