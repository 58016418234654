import { createActionSet } from "../helpers/Helpers";

//SELL ACTION SET
export const SELL = createActionSet("SELL");

//PRODUCT ACTION SET
export const PRODUCT = createActionSet("PRODUCT");

//CATEGORY ACTION SET
export const CATEGORY_LIST = createActionSet("CATEGORY_LIST");
export const ADD_CATEGORY = createActionSet("ADD_CATEGORY");

//ORDER ACTION SET
export const ORDER = createActionSet("ORDER");

//PRODUCTFORUSER ACTION SET
export const PRODUCTFORUSER = createActionSet("PRODUCTFORUSER");

//SIGNIN ACTION SET
export const SIGNIN = createActionSet("SIGNIN");

//LOGIN ACTION SET
export const LOGIN = createActionSet("LOGIN");
export const PASSWORD = createActionSet("PASSWORD");

//NEWS ACTION SET
export const NEWS = createActionSet("NEWS");
export const ADD_NEWS = createActionSet("ADD_NEWS");
export const DELETE_NEWS = createActionSet("DELETE_NEWS");
export const NEWS_DETAIL = createActionSet("NEWS_DETAIL");
export const EDIT_NEWS = createActionSet("EDIT_NEWS");

//PUBLICATION ACTION SET
export const PUBLICATION = createActionSet("PUBLICATION");
export const ADD_PUBLICATION = createActionSet("ADD_PUBLICATION");
export const DELETE_PUBLICATION = createActionSet("DELETE_PUBLICATION");
export const PUBLICATION_DETAIL = createActionSet("PUBLICATION_DETAIL");
export const EDIT_PUBLICATION = createActionSet("EDIT_PUBLICATION");

//MEDIAOUTPUT ACTION SET
export const MEDIAOUTPUT = createActionSet("MEDIAOUTPUT");
export const ADD_MEDIAOUTPUT = createActionSet("ADD_MEDIAOUTPUT");
export const DELETE_MEDIAOUTPUT = createActionSet("DELETE_MEDIAOUTPUT");
export const MEDIAOUTPUT_DETAIL = createActionSet("MEDIAOUTPUT_DETAIL");
export const EDIT_MEDIAOUTPUT = createActionSet("EDIT_MEDIAOUTPUT");

//EVENT ACTION SET
export const EVENT = createActionSet("EVENT");
export const ADD_EVENT = createActionSet("ADD_EVENT");
export const DELETE_EVENT = createActionSet("DELETE_EVENT");
export const EVENT_DETAIL = createActionSet("EVENT_DETAIL");
export const EDIT_EVENT = createActionSet("EDIT_EVENT");

//NEWLETTER ACTION SET
export const NEWLETTER = createActionSet("NEWLETTER");
export const NEWLETTER_DETAIL = createActionSet("NEWLETTER_DETAIL");
export const DELETE_NEWLETTER = createActionSet("DELETE_NEWLETTER");
export const ADD_NEWLETTER = createActionSet("ADD_NEWLETTER");
export const EDIT_NEWLETTER = createActionSet("EDIT_NEWLETTER");

//COLLABORATORS ACTION SET
export const COLLABORATORS = createActionSet("COLLABORATORS");
export const ADD_COLLABORATORS = createActionSet("ADD_COLLABORATORS");
export const DELETE_COLLABORATORS = createActionSet("DELETE_COLLABORATORS");
export const COLLABORATORS_DETAIL = createActionSet("COLLABORATORS_DETAIL");
export const EDIT_COLLABORATORS = createActionSet("EDIT_COLLABORATORS");

//TEAMMEMBER ACTION SET
export const TEAMMEMBER = createActionSet("TEAMMEMBER");
export const TEAMMEMBER_DETAIL = createActionSet("TEAMMEMBER_DETAIL");
export const ADD_TEAMMEMBER = createActionSet("ADD_TEAMMEMBER");
export const EDIT_TEAMMEMBER = createActionSet("EDIT_TEAMMEMBER");
export const DELETE_TEAMMEMBER = createActionSet("DELETE_TEAMMEMBER");

//CONFERENCEPRESENTATION ACTION SET
export const CONFERENCEPRESENTATION = createActionSet("CONFERENCEPRESENTATION");
export const ADD_CONFERENCEPRESENTATION = createActionSet(
  "ADD_CONFERENCEPRESENTATION",
);

//RESEARCHPAPER ACTION SET
export const RESEARCHPAPER = createActionSet("RESEARCHPAPER");
export const ADD_RESEARCHPAPER = createActionSet("ADD_RESEARCHPAPER");

//VACANCY ACTION SET
export const VACANCY = createActionSet("VACANCY");
export const ADD_VACANCY = createActionSet("ADD_VACANCY");
export const DELETE_VACANCY = createActionSet("DELETE_VACANCY");
export const VACANCY_DETAIL = createActionSet("VACANCY_DETAIL");
export const EDIT_VACANCY = createActionSet("EDIT_VACANCY");

//CONTACT ACTION SET
export const CONTACT = createActionSet("CONTACT");

//RESEARCH ACTION SET
export const RESEARCH = createActionSet("RESEARCH");
export const ADD_RESEARCH = createActionSet("ADD_RESEARCH");
export const DELETE_RESEARCH = createActionSet("DELETE_RESEARCH");
export const EDIT_RESEARCH = createActionSet("EDIT_RESEARCH");
export const RESEARCH_DETAIL = createActionSet("RESEARCH_DETAIL");

//RESEARCH ACTION SET
export const RESEARCH_PROGRAM = createActionSet("RESEARCH_PROGRAM");
export const ADD_RESEARCH_PROGRAM = createActionSet("ADD_RESEARCH_PROGRAM");
export const DELETE_RESEARCH_PROGRAM = createActionSet(
  "DELETE_RESEARCH_PROGRAM",
);
export const EDIT_RESEARCH_PROGRAM = createActionSet("EDIT_RESEARCH_PROGRAM");
export const RESEARCH_PROGRAM_DETAIL = createActionSet(
  "RESEARCH_PROGRAM_DETAIL",
);
// export const RESEARCH_PROGRAM_BY_STATUS = createActionSet(
//   "RESEARCH_PROGRAM_BY_STATUS",
// );
//STUDY ACTION SET
export const STUDY = createActionSet("STUDY");
export const ADD_STUDY = createActionSet("ADD_STUDY");
export const DELETE_STUDY = createActionSet("DELETE_STUDY");
export const EDIT_STUDY = createActionSet("EDIT_STORY");
export const STUDY_DETAIL = createActionSet("STUDY_DETAIL");

export const DELETE_CONTACT = createActionSet("DELETE_CONTACT");
