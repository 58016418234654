import {
  RESEARCH_PROGRAM,
  ADD_RESEARCH_PROGRAM,
  DELETE_RESEARCH_PROGRAM,
  EDIT_RESEARCH_PROGRAM,
  RESEARCH_PROGRAM_DETAIL,
} from "../actions/Actions";

const initalState = {
  listResearchProgramLoader: false,
  listResearchProgramCount: null,
  listResearchPorgram: [],
  addResearchProgramLoader: false,
  deleteResearchProgramLoader: false,
  researchProgramdetail: null,
  researchProgramdetailLoader: false,
  editResearchProgramLoader: false,
  listResearchPorgramByStatusLoader: false,
};

export function researchProgramReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case RESEARCH_PROGRAM.LOADING:
      return {
        ...state,
        listResearchProgramLoader: true,
      };
    case RESEARCH_PROGRAM.SUCCESS:
      return {
        ...state,
        listResearchProgramLoader: false,
        listResearchProgramCount: payload.total,
        listResearchPorgram: payload.rows,
      };
    case RESEARCH_PROGRAM.ERROR:
      return {
        ...state,
        listResearchProgramLoader: false,
        listResearchPorgram: [],
      };

    // GET DETAIL
    case RESEARCH_PROGRAM_DETAIL.LOADING:
      return {
        ...state,
        researchProgramdetailLoader: true,
        researchProgramdetail: null,
      };
    case RESEARCH_PROGRAM_DETAIL.SUCCESS:
      return {
        ...state,
        researchProgramdetailLoader: false,
        researchProgramdetail: payload,
      };
    case RESEARCH_PROGRAM_DETAIL.ERROR:
      return {
        ...state,
        researchProgramdetailLoader: false,
      };

    //  ADD
    case ADD_RESEARCH_PROGRAM.LOADING:
      return {
        ...state,
        addResearchProgramLoader: true,
      };
    case ADD_RESEARCH_PROGRAM.SUCCESS:
      return {
        ...state,
        addResearchProgramLoader: false,
      };
    case ADD_RESEARCH_PROGRAM.ERROR:
      return {
        ...state,
        addResearchProgramLoader: false,
      };

    //  edit
    case EDIT_RESEARCH_PROGRAM.LOADING:
      return {
        ...state,
        editResearchProgramLoader: true,
      };
    case EDIT_RESEARCH_PROGRAM.SUCCESS:
      return {
        ...state,
        editResearchProgramLoader: false,
      };
    case EDIT_RESEARCH_PROGRAM.ERROR:
      return {
        ...state,
        editResearchProgramLoader: false,
      };

    // DELETE
    case DELETE_RESEARCH_PROGRAM.LOADING:
      return {
        ...state,
        deleteResearchProgramLoader: true,
      };
    case DELETE_RESEARCH_PROGRAM.SUCCESS:
      return {
        ...state,
        deleteResearchProgramLoader: false,
      };
    case DELETE_RESEARCH_PROGRAM.ERROR:
      return {
        ...state,
        deleteResearchProgramLoader: false,
      };
    default:
      return state;
  }
}
