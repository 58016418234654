import { ad2bs } from "ad-bs-converter";

export const getCurrentDateAd = () => {
  const currentTimeStamp = Date.now();
  const currentDate = new Date(currentTimeStamp);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const formattedDateAD = `${year}/${month}/${day}`;
  return formattedDateAD;
};

export const getCurrentDateBs = (dateAD) => {
  const unformattedDateBs = ad2bs(dateAD);
  const formattedDateBs = `${unformattedDateBs.ne.year}/${unformattedDateBs.ne.month}/${unformattedDateBs.ne.day}`;
  return formattedDateBs;
};
