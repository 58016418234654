import LoginPage from "../loginPage/LoginPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import NewsPage from "../newsPage/NewsPage";
import AddNewsPage from "../newsPage/addNewsPage/AddNewsPage";
import PublicationPage from "../publicationPage/PublicationPage";
import MediaOutputPage from "../mediaOutputPage/MediaOutputPage";
import AddMediaOutputPage from "../mediaOutputPage/addMediaOutputPage/AddMediaOutputPage";
import EditMediaOutputPage from "../mediaOutputPage/editMediaOutputPage/EditMediaOutputPage";
import AddPublicationPage from "../publicationPage/addPublicationPage/AddPublicationPage";
import EventPage from "../eventPage/EventPage";
import AddEventPage from "../eventPage/addEventPage/AddEventPage";
import EditEventPage from "../eventPage/editEventPage/EditEventPage";
import AddNewsLetterPage from "../newLetterPage/addNewsLetterPage/AddNewsLetterPage";
import NewLetterPage from "../newLetterPage/NewLetterPage";
import CollaboratorsPage from "../collaboratorsPage/CollaboratorsPage";
import AddCollaboratorPage from "../collaboratorsPage/addCollaboratorPage/AddCollaboratorPage";
import EditCollaboratorPage from "../collaboratorsPage/editCollaboratorPage/EditCollaboratorPage";
import TeamMemberPage from "../teamMemberPage/TeamMemberPage";
import AddTeamMemberPage from "../teamMemberPage/addTeamMemberPage/AddTeamMemberPage";
import EditNewsPage from "../newsPage/editNewsPage/EditNewsPage";
import EditTeamMemberPage from "../teamMemberPage/editTeamMemberPage/EditTeamMemberPage";
import EditNewsLetterPage from "../newLetterPage/editNewsLetterPage/EditNewsLetterPage";
import VacanyPage from "../vacancyPage/VacancyPage";
import AddVacancyPage from "../vacancyPage/addVacancyPage/AddVacancyPage";
import EditVacancyPage from "../vacancyPage/editVacancyPage/EditVacancyPage";
import EditPublicationPage from "../publicationPage/editPublicationPage/EditPublicationPage";
import ResearchPage from "../researchPage/ResearchPage";
import AddResearchPage from "../researchPage/addResearchPage/AddResearchPage";
import StudyPage from "../studyPage/StudyPage";
import AddStudyPage from "../studyPage/AddStudyPage/addStudyPage";

import ContactPage from "../contactPage/ContactPage";
import EditResearchPage from "../researchPage/editResearchPage/EditResearchPage";
import EditStudyPage from "../studyPage/editStudyPage/EditStudyPage";
import ResearchProgramPage from "../researchProgramPage/ResearchProgramPage";
import AddReseachProgramPage from "../researchProgramPage/addResearchProgramPage/AddReseachProgramPage";
import EditResearchProgramPage from "../researchProgramPage/editResearchProgramPage/EditResearchProgramPage";
export const publicPaths = [
  {
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    path: null,
    component: NotFoundPage,
  },
];

export const privatePaths = [
  {
    name: "Research Program",
    path: "/research-program",
    component: ResearchProgramPage,
    subPaths: [
      {
        name: "Add Research Program",
        path: "/add",
        component: AddReseachProgramPage,
        props: "isSub",
      },
      {
        name: "Edit Research Program",
        path: "/edit/:id",
        component: EditResearchProgramPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "News",
    path: "/news",
    component: NewsPage,
    subPaths: [
      {
        name: "Add News",
        path: "/add",
        component: AddNewsPage,
        props: "isSub",
      },
      {
        name: "Edit News",
        path: "/edit/:id",
        component: EditNewsPage,
        props: "isSub",
      },
    ],
  },

  {
    name: "Publication",
    path: "/publication",
    component: PublicationPage,
    subPaths: [
      {
        name: "Add Publication",
        path: "/add",
        component: AddPublicationPage,
        props: "isSub",
      },
      {
        name: "Edit Publication",
        path: "/edit/:id",
        component: EditPublicationPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Presentation",
    path: "/media-output",
    component: MediaOutputPage,
    subPaths: [
      {
        name: "Add Presentation",
        path: "/add",
        component: AddMediaOutputPage,
        props: "isSub",
      },
      {
        name: "Edit Presentation",
        path: "/edit/:id",
        component: EditMediaOutputPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Event",
    path: "/event",
    component: EventPage,
    subPaths: [
      {
        name: "Add Event",
        path: "/add",
        component: AddEventPage,
        props: "isSub",
      },
      {
        name: "Edit Event",
        path: "/edit/:id",
        component: EditEventPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "NewsLetter",
    path: "/news-letter",
    component: NewLetterPage,
    subPaths: [
      {
        name: "Add NewsLetter",
        path: "/add",
        component: AddNewsLetterPage,
        props: "isSub",
      },
      {
        name: "Edit NewsLetter",
        path: "/edit/:id",
        component: EditNewsLetterPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Collaborator",
    path: "/collaborator",
    component: CollaboratorsPage,
    subPaths: [
      {
        name: "Add Collaborator",
        path: "/add",
        component: AddCollaboratorPage,
        props: "isSub",
      },
      {
        name: "Edit Collaborator",
        path: "/edit/:id",
        component: EditCollaboratorPage,
        props: "isSub",
      },
    ],
  },

  {
    name: "Research",
    path: "/research",
    component: ResearchPage,
    subPaths: [
      {
        name: "Add Research",
        path: "/add",
        component: AddResearchPage,
        props: "isSub",
      },
      {
        name: "Edit Research",
        path: "/edit/:id",
        component: EditResearchPage,
        props: "isSub",
      },
    ],
  },

  {
    name: "Study",
    path: "/study",
    component: StudyPage,
    subPaths: [
      {
        name: "Add Study",
        path: "/add",
        component: AddStudyPage,
        props: "isSub",
      },
      {
        name: "Edit Study",
        path: "/edit/:id",
        component: EditStudyPage,
        props: "isSub",
      },
    ],
  },

  {
    name: "Team Member",
    path: "/team-member",
    component: TeamMemberPage,
    subPaths: [
      {
        name: "Add Team Member",
        path: "/add",
        component: AddTeamMemberPage,
        props: "isSub",
      },
      {
        name: "Eidt Team Member",
        path: "/edit/:id",
        component: EditTeamMemberPage,
        props: "isSub",
      },
    ],
  },

  {
    name: "Vacancy",
    path: "/vacancy",
    component: VacanyPage,
    subPaths: [
      {
        name: "Add Vacancy",
        path: "/add",
        component: AddVacancyPage,
        props: "isSub",
      },
      {
        name: "Edit Vacancy",
        path: "/edit/:id",
        component: EditVacancyPage,
        props: "isSub",
      },
    ],
  },
  {
    name: "Contact",
    path: "/contact",
    component: ContactPage,
  },
];
