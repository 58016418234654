import {
  EVENT,
  ADD_EVENT,
  DELETE_EVENT,
  EVENT_DETAIL,
  EDIT_EVENT,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  editLoader: false,
  detailLoader: false,
  detail: null,
};

export function eventReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case EVENT.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case EVENT.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case EVENT.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    //  ADD
    case ADD_EVENT.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_EVENT.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_EVENT.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    // DELETE
    case DELETE_EVENT.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_EVENT.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_EVENT.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    // DETAIL
    case EVENT_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case EVENT_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case EVENT_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    // DELETE
    case EDIT_EVENT.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_EVENT.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_EVENT.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    default:
      return state;
  }
}
