import {
  PUBLICATION,
  ADD_PUBLICATION,
  DELETE_PUBLICATION,
  PUBLICATION_DETAIL,
  EDIT_PUBLICATION,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getPublicationAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;

    dispatch({ type: PUBLICATION.LOADING });
    try {
      if (researchProgramId) {
        res = await api(
          `${APIS.publication}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(
          `${APIS.publication}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      }

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: PUBLICATION.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PUBLICATION.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PUBLICATION.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getPublicationDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: PUBLICATION_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: PUBLICATION_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PUBLICATION_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PUBLICATION_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postPublicationAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_PUBLICATION.LOADING });
      res = await api(APIS.commonResearch, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_PUBLICATION.SUCCESS });
        toast({ message: "Publication successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_PUBLICATION.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_PUBLICATION.ERROR });
      console.error(message);
      toast({ message: "Error Adding Publication", type: "error" });
      return 0;
    }
  };
}

export function editPublicationAction(
  id,
  body,
  photoFormdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_PUBLICATION.LOADING });
      res = await api(`${APIS.commonResearch}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.common}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;
        if (photoSuccess === "true") {
          dispatch({ type: EDIT_PUBLICATION.SUCCESS });
          toast({
            message: "Publication successfully edited",
            type: "success",
          });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_PUBLICATION.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_PUBLICATION.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_PUBLICATION.ERROR });
      console.error(message);
      toast({ message: "Error editing Publication", type: "error" });
      return 0;
    }
  };
}

export function deletePublicationAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_PUBLICATION.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_PUBLICATION.SUCCESS });
        toast({ message: "Publication successfully deleted", type: "success" });
        dispatch(getPublicationAction(page));
      } else {
        dispatch({ type: DELETE_PUBLICATION.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_PUBLICATION.ERROR });
      toast({ message: "Error Deleting Publication", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
