import { combineReducers } from "redux";
import { loginReducer } from "./Login.reducer";
import { signinReducer } from "./Signin.reducer";
import { newsReducer } from "./News.reducer";
import { publicationReducer } from "./Publication.reducer";
import { mediaOutputReducer } from "./MediaOutput.reducer";
import { eventReducer } from "./Event.reducer";
import { newsletterReducer } from "./NewLetter.reducer";
import { collaboratorsReducer } from "./Collaborators.reducer";
import { teammemberReducer } from "./TeamMember.reducer";
import { conferencePresentationReducer } from "./ConferencePresentation.reducer";
import { researchPaperReducer } from "./ResearchPaper.reducer";
import { vacancyReducer } from "./Vacancy.reducer";
import { contactReducer } from "./Contact.reducer";
import { researchReducer } from "./Research.reducer";
import { researchProgramReducer } from "./ResearchProgram.reducer";
import { studyReducer } from "./Study.reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  signin: signinReducer,
  news: newsReducer,
  publication: publicationReducer,
  mediaOutput: mediaOutputReducer,
  event: eventReducer,
  newsLetter: newsletterReducer,
  collaborator: collaboratorsReducer,
  teamMember: teammemberReducer,
  conferencePresentation: conferencePresentationReducer,
  researchPaper: researchPaperReducer,
  vacancy: vacancyReducer,
  contact: contactReducer,
  research: researchReducer,
  researchProgram: researchProgramReducer,
  study: studyReducer,
});

export default rootReducer;
