import {
  PUBLICATION,
  ADD_PUBLICATION,
  DELETE_PUBLICATION,
  PUBLICATION_DETAIL,
  EDIT_PUBLICATION,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  detailLoader: false,
  detail: null,
  editLoader: false,
};

export function publicationReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case PUBLICATION.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case PUBLICATION.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case PUBLICATION.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // GET DETAIL
    case PUBLICATION_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case PUBLICATION_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case PUBLICATION_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    //  ADD
    case ADD_PUBLICATION.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_PUBLICATION.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_PUBLICATION.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    //  edit
    case EDIT_PUBLICATION.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_PUBLICATION.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_PUBLICATION.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // DELETE
    case DELETE_PUBLICATION.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_PUBLICATION.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_PUBLICATION.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    default:
      return state;
  }
}
