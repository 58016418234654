import { CONTACT, DELETE_CONTACT } from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getContactAction(page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CONTACT.LOADING });
      res = await api(`${APIS.contact}&page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: CONTACT.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: CONTACT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: CONTACT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteContactAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_CONTACT.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_CONTACT.SUCCESS });
        toast({ message: "Contact successfully deleted", type: "success" });
        dispatch(getContactAction(page));
      } else {
        dispatch({ type: DELETE_CONTACT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_CONTACT.ERROR });
      toast({ message: "Error Deleting Contact", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
