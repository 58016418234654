import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import Select from "react-select";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postStudyAction, getResearchAction } from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 4,
    borderColor: "#E1E1E1",
    backgroundColor: "#F8F8F8",
    "&:hover": { borderColor: "#0284A4" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284A4" : "#fff",
      "&:hover": { backgroundColor: "#e9e9e9" },
    };
  },
};

const AddStudyPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { postStudyAction, getResearchAction, study, research } = props;
  const { addLoader } = study;
  const { list } = research;
  const { handleSubmit, register, control } = useForm();

  const [image, setImage] = useState([]);

  const onChange = (imageList) => {
    setImage(imageList);
  };

  useEffect(() => {
    getResearchAction();
  }, [getResearchAction]);

  const onSubmit = (data) => {
    const description = {
      study: {
        en: data.studyEng,
        np: data.studyNep,
      },
      name: {
        en: data.nameEng,
        np: data.nameNep,
      },
      title: {
        en: data.titleEng,
        np: data.titleNep,
      },
      background: {
        en: data.backgroundEng,
        np: data.backgroundNep,
      },
      aim: {
        en: data.aimEng,
        np: data.aimNep,
      },
      method: {
        en: data.methodEng,
        np: data.methodNep,
      },
      impact: {
        en: data.impactEng,
        np: data.impactNep,
      },
      team: {
        en: data.teamEng,
        np: data.teamNep,
      },
      link: data.linkEng,
    };

    const formdata = new FormData();

    formdata.append("description", JSON.stringify(description));
    formdata.append("category", data.research.id);
    formdata.append("type", "research");
    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("imageFile", element.file);
      });

    postStudyAction(formdata, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes.Study.path);
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Research</div>

              <Controller
                as={
                  <Select
                    isClearable={true}
                    getOptionValue={(option) => `${option.id}`}
                    getOptionLabel={(option) => `${option.cat_name}`}
                    options={list}
                    styles={selectStyles}
                    placeholder="Select Research"
                  />
                }
                name="research"
                control={control}
                defaultValue=""
                rules={{
                  required: { value: true, message: "This field is required" },
                }}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Study Name</div>

              <InputField name="studyEng" innerRef={register} />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Name</div>

              <InputField
                name="nameEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Project Title</div>

              <InputField
                name="titleEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Background</div>

              <TextAreaField
                name="backgroundEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Project Aim</div>

              <TextAreaField
                name="aimEng"
                innerRef={register({ required: true })}
              />
            </div>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Methods</div>

              <TextAreaField
                name="methodEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Anticipated Impact</div>

              <TextAreaField
                name="impactEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Study Team</div>

              <TextAreaField
                name="teamEng"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                Full text article available at
              </div>

              <TextAreaField name="linkEng" innerRef={register} />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">अध्ययन नाम</div>

              <InputField name="studyNep" innerRef={register} />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">नाम</div>

              <InputField
                name="nameNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">परियोजना शिर्षक</div>

              <InputField
                name="titleNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">पृष्ठभूमि</div>

              <TextAreaField
                name="backgroundNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">
                परियोजनाको उद्धेश्य
              </div>

              <TextAreaField
                name="aimNep"
                innerRef={register({ required: true })}
              />
            </div>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">विधीहरु</div>

              <TextAreaField
                name="methodNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">अपेक्षित प्रभाव</div>

              <TextAreaField
                name="impactNep"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">अध्ययन टोली</div>

              <TextAreaField
                name="teamNep"
                innerRef={register({ required: true })}
              />
            </div>

            <ImageUploading value={image} onChange={onChange} multiple={true}>
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                          overflow: "hidden",
                        }}>
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                        <AiFillCloseCircle
                          onClick={() => onImageRemove(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: 20,
                            width: 20,
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    className="fixedwidth"
                    onClick={onImageUpload}
                    variant="contained"
                    color="primary">
                    {image?.length > 0 ? "Change Image" : "Upload image"}
                  </Button>
                </div>
              )}
            </ImageUploading>

            <ActivityIndicator animating={addLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    study: state.study,
    research: state.research,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postStudyAction,
      getResearchAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudyPage);
