import {
  COLLABORATORS,
  ADD_COLLABORATORS,
  EDIT_COLLABORATORS,
  COLLABORATORS_DETAIL,
  DELETE_COLLABORATORS,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  editLoader: false,
  detailLoader: false,
  detail: null,
};

export function collaboratorsReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case COLLABORATORS.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case COLLABORATORS.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case COLLABORATORS.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // GET
    case COLLABORATORS_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case COLLABORATORS_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case COLLABORATORS_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    //  ADD
    case ADD_COLLABORATORS.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_COLLABORATORS.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_COLLABORATORS.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    //EDIT
    case EDIT_COLLABORATORS.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_COLLABORATORS.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_COLLABORATORS.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // DELETE
    case DELETE_COLLABORATORS.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_COLLABORATORS.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_COLLABORATORS.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };

    default:
      return state;
  }
}
