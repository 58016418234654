import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import Select from "react-select";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getResearchAction,
  deleteResearchAction,
  getResearchProgramAction,
} from "../../actions/Actions";

// HOCS
import CompWrapper from "../hocs/CompWrapper.hoc";

// COMMON
import { InvertedButton } from "../common/button/Button.common";
import Table from "../common/table/Table.common";

// HELPER
import { FILE_URL } from "../../config/Config";

const ResearchPage = (props) => {
  const {
    getResearchAction,
    deleteResearchAction,
    research,
    getResearchProgramAction,
    researchProgram,
  } = props;
  const { listLoader, listCount, list, deleteLoader } = research;
  const { listResearchPorgram } = researchProgram;

  const { location, navigation } = useNavigation();
  const { toast } = useAuth();
  const [researchProgramOption, setResearchProgramOption] = useState([]);
  const [selectedResearchProgram, setSelectedResearchProgram] = useState([]);
  const { navigate, routes } = navigation;
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(location?.search);
  }
  const pageNumber = query.get("page") || 1;

  useEffect(() => {
    getResearchAction(pageNumber, selectedResearchProgram?.id);
  }, [getResearchAction, pageNumber, selectedResearchProgram]);

  const deleteHandler = (id) => {
    // deleteResearchAction(id, toast, pageNumber);
    deleteResearchAction(id, toast, pageNumber);
  };

  const editHandler = (id) => {
    navigate(`research/edit/${id}`);
  };

  const handleResearchProgram = (selectedOptions) => {
    setSelectedResearchProgram(selectedOptions);
    navigate(location.pathname + `?page=` + Number(1));
    getResearchAction(pageNumber, selectedOptions?.id);
  };

  useEffect(() => {
    const remappedResearchProgramList = listResearchPorgram?.map(
      (item, index) => {
        return {
          id: item.id,
          label: item.name.en,
          value: item.name.en,
        };
      },
    );

    const allappendedResearchProgramList = [
      { id: 0, label: "All", value: "" },
      ...remappedResearchProgramList,
    ];
    setResearchProgramOption(allappendedResearchProgramList);
  }, [listResearchPorgram]);

  useEffect(() => {
    getResearchProgramAction(1);
  }, [getResearchProgramAction]);

  return (
    <CompWrapper>
      <div className="newspage-container">
        <div className="newspage">
          <div className="newspage-head">
            <div className="newspage-head-title">Research</div>
            <Select
              isMulti={false}
              options={researchProgramOption}
              value={selectedResearchProgram}
              onChange={handleResearchProgram}
              placeholder="Select Research Program"
            />
            <div className="newspage-head-button">
              <div style={{ width: 100 }}>
                <InvertedButton
                  title="Add"
                  onClick={() => {
                    navigate(routes["Add Research"].path);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="newspage-list">
            <Table
              columns={[
                {
                  field: "cat_image",
                  name: "Image",
                  render: (rowData) => (
                    <img
                      alt="cat_image"
                      src={FILE_URL + rowData}
                      style={{ height: 100, borderRadius: 4 }}
                    />
                  ),
                },
                {
                  field: "cat_description",
                  name: "title",
                  render: (rowData) => <p>{JSON.parse(rowData)?.title?.en}</p>,
                },
              ]}
              data={list}
              actions
              dataLoader={listLoader}
              totalCount={listCount}
              deleteLoader={deleteLoader}
              onDeleteHandler={deleteHandler}
              onEditHandler={editHandler}
            />
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    research: state.research,
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getResearchAction,
      deleteResearchAction,
      getResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchPage);
