import React, { useState } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";

// MATERIAL
import { Button } from "@material-ui/core";

// DRAFT
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postVacancyAction } from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import { InputField } from "../../common/inputField/InputField.common";

// HELPERS
import { validator, isValid } from "../../../utils/Validator.util";

const AddVacancyPage = (props) => {
  const { navigation } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { postVacancyAction, vacancy } = props;
  const { addLoader } = vacancy;
  const { handleSubmit, register } = useForm();

  const [engEditorState, setEngEditorState] = useState(
    EditorState.createEmpty(),
  );

  const onEngChangeText = (editorState) => {
    setEngEditorState(editorState);
  };

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);
    console.log(data, "data");

    const descEng = `${draftToHtml(
      convertToRaw(engEditorState.getCurrentContent()),
    )}`;

    // VALIDATION
    validate("descEng", descEng === `<p></p>\n`, () => {
      toast({ message: "English description empty", type: "error" });
    });
    console.log(data.expirationDate.length, "expiration date length");
    validate("expirationDate", data.expirationDate.length === 0, () => {
      toast({ message: "Please add expiration date", type: "error" });
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const description = {
      desc: descEng,
      expirationDate: data.expirationDate,
    };

    const formdata = new FormData();

    formdata.append("title", data.titleEng);
    formdata.append("description", JSON.stringify(description));
    formdata.append("type", "vacancy");

    postVacancyAction(formdata, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes.Vacancy.path);
  };

  return (
    <CompWrapper>
      <div className="addnews-container">
        <div className="addnews">
          <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Title</div>

              <InputField
                name="titleEng"
                placeholder="Title"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">ExpiryDate</div>

              <InputField
                name="expirationDate"
                type="date"
                placeholder="Deadline"
                innerRef={register({ required: true })}
              />
            </div>

            <div className="addnews-form-input">
              <div className="addnews-form-input-label">Description</div>
              <Editor
                name="descriptionEng"
                editorState={engEditorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEngChangeText}
              />
            </div>

            <ActivityIndicator animating={addLoader}>
              <Button
                className="fixedwidth"
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postVacancyAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVacancyPage);
