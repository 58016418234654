import {
  TEAMMEMBER,
  ADD_TEAMMEMBER,
  DELETE_TEAMMEMBER,
  EDIT_TEAMMEMBER,
  TEAMMEMBER_DETAIL,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getTeamMemberAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: TEAMMEMBER.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.researchTeams}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(
          `${APIS.researchTeams}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      }

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: TEAMMEMBER.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TEAMMEMBER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TEAMMEMBER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getTeamMemberDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: TEAMMEMBER_DETAIL.LOADING });
      res = await api(`${APIS.team}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: TEAMMEMBER_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TEAMMEMBER_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TEAMMEMBER_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postTeamMemberAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_TEAMMEMBER.LOADING });
      res = await api(APIS.createTeam, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_TEAMMEMBER.SUCCESS });
        toast({ message: "Team Member successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_TEAMMEMBER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_TEAMMEMBER.ERROR });
      console.error(message);
      toast({ message: "Error Adding Team Member", type: "error" });
      return 0;
    }
  };
}

export function editTeamMemberAction(id, body, image, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_TEAMMEMBER.LOADING });
      res = await api(`${APIS.createTeam}/${id}`, "PATCH", body);
      const { success, data } = res.data;

      if (success === "true") {
        if (image.length > 0) {
          const photoFormdata = new FormData();

          photoFormdata.append("type", "team");
          photoFormdata.append("imageFile", image[0].file);

          let photoRes = await api(
            `${APIS.team}/image/${id}`,
            "PATCH",
            photoFormdata,
            { file: true },
          );
          const { success: photoSuccess, data: photoData } = photoRes.data;
          if (photoSuccess === "true") {
            dispatch({ type: EDIT_TEAMMEMBER.SUCCESS });
            toast({
              message: "Team Member successfully edited",
              type: "success",
            });
            goBackHandler();
          } else {
            dispatch({ type: EDIT_TEAMMEMBER.ERROR });
            toast({ message: photoData.message, type: "error" });
          }
        } else {
          dispatch({ type: EDIT_TEAMMEMBER.SUCCESS });
          toast({
            message: "Team Member successfully edited",
            type: "success",
          });
          goBackHandler();
        }
      } else {
        dispatch({ type: EDIT_TEAMMEMBER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_TEAMMEMBER.ERROR });
      console.error(message);
      toast({ message: "Error editing Team Member", type: "error" });
      return 0;
    }
  };
}

export function deleteTeamMemberAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_TEAMMEMBER.LOADING });
      res = await api(`${APIS.team}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_TEAMMEMBER.SUCCESS });
        toast({ message: "Team Member successfully deleted", type: "success" });
        dispatch(getTeamMemberAction(page));
      } else {
        dispatch({ type: DELETE_TEAMMEMBER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_TEAMMEMBER.ERROR });
      toast({ message: "Error Deleting Team Member", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
