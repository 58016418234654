import {
  NEWLETTER,
  ADD_NEWLETTER,
  EDIT_NEWLETTER,
  DELETE_NEWLETTER,
  NEWLETTER_DETAIL,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getNewsLetterAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: NEWLETTER.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.newsLetter}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(`${APIS.newsLetter}&page=${page}&limit=${TABLE_LIMIT}`);
      }

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: NEWLETTER.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: NEWLETTER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: NEWLETTER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getNewsLetterDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: NEWLETTER_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: NEWLETTER_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: NEWLETTER_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: NEWLETTER_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postNewletterAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_NEWLETTER.LOADING });
      res = await api(APIS.commonResearch, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_NEWLETTER.SUCCESS });
        toast({ message: "News successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_NEWLETTER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_NEWLETTER.ERROR });
      console.error(message);
      toast({ message: "Error Adding News", type: "error" });
      return 0;
    }
  };
}

export function editNewletterAction(
  id,
  body,
  photoFormdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_NEWLETTER.LOADING });
      res = await api(`${APIS.commonResearch}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.common}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;
        if (photoSuccess === "true") {
          dispatch({ type: EDIT_NEWLETTER.SUCCESS });
          toast({ message: "Newsletter successfully edited", type: "success" });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_NEWLETTER.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_NEWLETTER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_NEWLETTER.ERROR });
      console.error(message);
      toast({ message: "Error editing Newsletter", type: "error" });
      return 0;
    }
  };
}

export function deleteNewsLetterAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_NEWLETTER.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_NEWLETTER.SUCCESS });
        toast({ message: "Newsletter successfully deleted", type: "success" });
        dispatch(getNewsLetterAction(page));
      } else {
        dispatch({ type: DELETE_NEWLETTER.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_NEWLETTER.ERROR });
      toast({ message: "Error Deleting Newsletter", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
