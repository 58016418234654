import {
  VACANCY,
  ADD_VACANCY,
  VACANCY_DETAIL,
  DELETE_VACANCY,
  EDIT_VACANCY,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getVacancyAction(page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: VACANCY.LOADING });
      res = await api(`${APIS.vacancy}&page=${page}&limit=${TABLE_LIMIT}`);

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: VACANCY.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: VACANCY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: VACANCY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getVacancyDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: VACANCY_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: VACANCY_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: VACANCY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: VACANCY_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postVacancyAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_VACANCY.LOADING });
      res = await api(APIS.common, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_VACANCY.SUCCESS });
        toast({ message: "Vacancy successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_VACANCY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_VACANCY.ERROR });
      console.error(message);
      toast({ message: "Error Adding Vacancy", type: "error" });
      return 0;
    }
  };
}

export function editVacancyAction(id, formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_VACANCY.LOADING });
      res = await api(`${APIS.common}/${id}`, "PATCH", formdata);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EDIT_VACANCY.SUCCESS });
        toast({ message: "Vacancy successfully Edited", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: EDIT_VACANCY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_VACANCY.ERROR });
      console.error(message);
      toast({ message: "Error Editing Vacancy", type: "error" });
      return 0;
    }
  };
}

export function deleteVacancyAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_VACANCY.LOADING });
      res = await api(`${APIS.common}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_VACANCY.SUCCESS });
        toast({ message: "Vacancy successfully deleted", type: "success" });
        dispatch(getVacancyAction(page));
      } else {
        dispatch({ type: DELETE_VACANCY.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_VACANCY.ERROR });
      toast({ message: "Error Deleting Vacancy", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
