import React, { useEffect, useState } from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm } from "react-hook-form";

// MATERIAL
import { Button } from "@material-ui/core";

// DRAFT
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editVacancyAction,
  getVacancyDetailAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import { InputField } from "../../common/inputField/InputField.common";
import DotPulse from "../../common/dotPulse/DotPulse.common";

// HELPERS
import { validator, isValid } from "../../../utils/Validator.util";

const EditVacancyPage = (props) => {
  const { navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const { editVacancyAction, getVacancyDetailAction, vacancy } = props;
  const { editLoader, detail } = vacancy;
  const { handleSubmit, register } = useForm();

  const [engEditorState, setEngEditorState] = useState();

  useEffect(() => {
    getVacancyDetailAction(Number(params.id));
  }, [getVacancyDetailAction, params.id]);

  useEffect(() => {
    if (!!detail) {
      const blocksFromHtmlEng = htmlToDraft(detail.description.desc);

      const { contentBlocks, entityMap } = blocksFromHtmlEng;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      setEngEditorState(EditorState.createWithContent(contentState));
    }
  }, [detail]);

  const onEngChangeText = (editorState) => {
    setEngEditorState(editorState);
  };

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);

    const descEng = `${draftToHtml(
      convertToRaw(engEditorState.getCurrentContent()),
    )}`;

    // VALIDATION
    validate("descEng", descEng === `<p></p>\n`, () => {
      toast({ message: "English description empty", type: "error" });
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const description = {
      desc: descEng,
      expirationDate: data.expirationDate,
    };

    const body = {
      title: data.titleEng,
      description: JSON.stringify(description),
      type: "vacancy",
    };

    editVacancyAction(Number(params.id), body, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes.Vacancy.path);
  };

  return (
    <CompWrapper>
      {!detail ? (
        <DotPulse />
      ) : (
        <div className="addnews-container">
          <div className="addnews">
            <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Title</div>

                <InputField
                  name="titleEng"
                  placeholder="Event Title"
                  defaultValue={detail?.title}
                  innerRef={register({ required: true })}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">ExpiryDate</div>

                <InputField
                  name="expirationDate"
                  type="date"
                  placeholder="Deadline"
                  defaultValue={detail?.description.expirationDate}
                  innerRef={register({ required: true })}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Description</div>
                <Editor
                  name="descriptionEng"
                  editorState={engEditorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEngChangeText}
                />
              </div>

              <ActivityIndicator animating={editLoader}>
                <Button
                  className="fixedwidth"
                  variant="contained"
                  color="primary"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editVacancyAction,
      getVacancyDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVacancyPage);
