import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const PVT_KEY =
  "jNH-fcR|tJW)6`td8{/),Px;yCqgg;v65@|Vf?#E:ip^1C)kEYdzUGZZnE}BLTw";
// const SIGN_KEY = Date.now();
// const SECURE_KEY = PVT_KEY + SIGN_KEY;

const cookies = new Cookies();

// TO SET COOKIES
export function setCookie(key, value) {
  cookies.set([key], value, { path: "/", maxAge: 82800 });
}

// TO GET VALUES
export function getCookie(key) {
  return cookies.get([key]);
}

// TO CLEAR THE COOKIE
export function removeCookie(key) {
  cookies.remove([key], { path: "/" });
}

//ENCRYPT
export function encrypt(text, SIGN_TIME) {
  return CryptoJS.AES.encrypt(text, PVT_KEY + SIGN_TIME).toString();
}

//DECRYPT
export function decrypter(ciphertext, SIGN_TIME) {
  let bytes = CryptoJS.AES.decrypt(ciphertext, PVT_KEY + SIGN_TIME);
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
