import {
  RESEARCH_PROGRAM_DETAIL,
  RESEARCH_PROGRAM,
  ADD_RESEARCH_PROGRAM,
  EDIT_RESEARCH_PROGRAM,
  DELETE_RESEARCH_PROGRAM,
} from "./Actions";
import { api, APIS, SELECT_FIELD_LIMIT, TABLE_LIMIT } from "../config/Config";

export function getResearchProgramAction(
  page = 1,
  status,
  isResearchProgramPage,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: RESEARCH_PROGRAM.LOADING });

      if (status) {
        res = await api(
          `${APIS.researchProgram}/?page=${page}&limit=${
            isResearchProgramPage ? TABLE_LIMIT : SELECT_FIELD_LIMIT
          }&status=${status}`,
        );
      } else {
        res = await api(
          `${APIS.researchProgram}/?page=${page}&limit=${
            isResearchProgramPage ? TABLE_LIMIT : SELECT_FIELD_LIMIT
          }`,
        );
      }

      const { success, data } = res?.data;
      if (success === "true") {
        dispatch({ type: RESEARCH_PROGRAM.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: RESEARCH_PROGRAM.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: RESEARCH_PROGRAM.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getResearchProgramDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: RESEARCH_PROGRAM_DETAIL.LOADING });
      res = await api(`${APIS.researchProgram}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: RESEARCH_PROGRAM_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: RESEARCH_PROGRAM_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: RESEARCH_PROGRAM_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postResearchProgramAction(
  researchProgramData,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_RESEARCH_PROGRAM.LOADING });
      res = await api(APIS.researchProgram, "POST", researchProgramData);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_RESEARCH_PROGRAM.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        goBackHandler();
      } else {
        dispatch({ type: ADD_RESEARCH_PROGRAM.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_RESEARCH_PROGRAM.ERROR });
      console.error(message);
      toast({ message: "Error Adding Research Program", type: "error" });
      return 0;
    }
  };
}

export function editResearchProgramAction(
  researchProgramId,
  body,
  //   photoFormdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_RESEARCH_PROGRAM.LOADING });
      res = await api(
        `${APIS.researchProgram}/${researchProgramId}`,
        "PATCH",
        body,
      );

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EDIT_RESEARCH_PROGRAM.SUCCESS });
        toast({
          message: data.message,
          type: "success",
        });
        goBackHandler();
      } else {
        dispatch({ type: ADD_RESEARCH_PROGRAM.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_RESEARCH_PROGRAM.ERROR });
      console.error(message);
      toast({ message: message, type: "error" });
      return 0;
    }
  };
}

export function deleteResearchProgramAction(researchProgramId, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_RESEARCH_PROGRAM.LOADING });
      res = await api(`${APIS.researchProgram}/${researchProgramId}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_RESEARCH_PROGRAM.SUCCESS });
        toast({
          message: "Research Program successfully deleted",
          type: "success",
        });
        dispatch(getResearchProgramAction(page));
      } else {
        dispatch({ type: DELETE_RESEARCH_PROGRAM.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_RESEARCH_PROGRAM.ERROR });
      toast({ message: "Error Deleting Research Program", type: "error" });
      console.error(message);
      return 0;
    }
  };
}

// export function getResearchProgramByStatusAction(page = 1, status) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: RESEARCH_PROGRAM_BY_STATUS.LOADING });
//       res = await api(
//         `${APIS.researchProgram}/status/?page=${page}&limit=${TABLE_LIMIT}&status=${status}`,
//       );

//       const { success, data } = res?.data;
//       if (success === "true") {
//         dispatch({
//           type: RESEARCH_PROGRAM_BY_STATUS.SUCCESS,
//           payload: data.data,
//         });
//       } else {
//         dispatch({ type: RESEARCH_PROGRAM_BY_STATUS.ERROR });
//       }
//     } catch ({ message }) {
//       dispatch({ type: RESEARCH_PROGRAM_BY_STATUS.ERROR });
//       console.error(message);
//       return 0;
//     }
//   };
// }
