import {
  RESEARCH,
  ADD_RESEARCH,
  DELETE_RESEARCH,
  EDIT_RESEARCH,
  RESEARCH_DETAIL,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getResearchAction(page = 1, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: RESEARCH.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.category}/all/dashboard?researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(
          `${APIS.category}/all/dashboard?page=${page}&limit=${TABLE_LIMIT}`,
        );
      }

      const { success, data } = res?.data;
      if (success === "true") {
        dispatch({ type: RESEARCH.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: RESEARCH.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: RESEARCH.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getResearchDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: RESEARCH_DETAIL.LOADING });
      res = await api(`${APIS.category}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: RESEARCH_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: RESEARCH_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: RESEARCH_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postResearchAction(
  formdata,
  goBackHandler,
  toast,
  researchProgramId,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_RESEARCH.LOADING });
      res = await api(APIS.researchCategory, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_RESEARCH.SUCCESS });
        toast({ message: "Research successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_RESEARCH.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_RESEARCH.ERROR });
      console.error(message);
      toast({ message: "Error Adding Research", type: "error" });
      return 0;
    }
  };
}

export function editResearchAction(
  id,
  body,
  photoFormdata,
  goBackHandler,
  toast,
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_RESEARCH.LOADING });
      res = await api(`${APIS.researchCategory}/${id}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        let photoRes = await api(
          `${APIS.category}/image/${id}`,
          "PATCH",
          photoFormdata,
          { file: true },
        );
        const { success: photoSuccess, data: photoData } = photoRes.data;
        if (photoSuccess === "true") {
          dispatch({ type: EDIT_RESEARCH.SUCCESS });
          toast({
            message: "Research successfully edited",
            type: "success",
          });
          goBackHandler();
        } else {
          dispatch({ type: EDIT_RESEARCH.ERROR });
          toast({ message: photoData.message, type: "error" });
        }
      } else {
        dispatch({ type: EDIT_RESEARCH.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_RESEARCH.ERROR });
      console.error(message);
      toast({ message: "Error editing Research", type: "error" });
      return 0;
    }
  };
}

export function deleteResearchAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_RESEARCH.LOADING });
      res = await api(`${APIS.category}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_RESEARCH.SUCCESS });
        toast({ message: "Research successfully deleted", type: "success" });
        dispatch(getResearchAction(page));
      } else {
        dispatch({ type: DELETE_RESEARCH.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_RESEARCH.ERROR });
      toast({ message: "Error Deleting Research", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
