import {
  RESEARCH,
  ADD_RESEARCH,
  DELETE_RESEARCH,
  EDIT_RESEARCH,
  RESEARCH_DETAIL,
} from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  addLoader: false,
  deleteLoader: false,
  detail: null,
  detailLoader: false,
  editLoader: false,
};

export function researchReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case RESEARCH.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case RESEARCH.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload,
      };
    case RESEARCH.ERROR:
      return {
        ...state,
        listLoader: false,
        list: [],
      };

    // GET DETAIL
    case RESEARCH_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case RESEARCH_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case RESEARCH_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    //  ADD
    case ADD_RESEARCH.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case ADD_RESEARCH.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case ADD_RESEARCH.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    //  edit
    case EDIT_RESEARCH.LOADING:
      return {
        ...state,
        editLoader: true,
      };
    case EDIT_RESEARCH.SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case EDIT_RESEARCH.ERROR:
      return {
        ...state,
        editLoader: false,
      };

    // DELETE
    case DELETE_RESEARCH.LOADING:
      return {
        ...state,
        deleteLoader: true,
      };
    case DELETE_RESEARCH.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case DELETE_RESEARCH.ERROR:
      return {
        ...state,
        deleteLoader: false,
      };
    default:
      return state;
  }
}
