import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigation, useAuth } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

// MATERIAL
import { Button } from "@material-ui/core";

// ACTIONS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editTeamMemberAction,
  getTeamMemberDetailAction,
  getResearchProgramAction,
} from "../../../actions/Actions";

// HOCS
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

// COMMON
import {
  InputField,
  TextAreaField,
} from "../../common/inputField/InputField.common";
import DotPulse from "../../common/dotPulse/DotPulse.common";

// HELPERS
import { FILE_URL } from "../../../config/Config";
import { validator, isValid } from "../../../utils/Validator.util";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 4,
    borderColor: "#E1E1E1",
    backgroundColor: "#F8F8F8",
    "&:hover": { borderColor: "#0284A4" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284A4" : "#fff",
      "&:hover": { backgroundColor: "#e9e9e9" },
    };
  },
};

const options = [
  {
    valueEng: "The Directors",
    valueNep: "निर्देशकहरु",
    label: "The Directors",
  },
  {
    valueEng: "Research Team",
    valueNep: "अन्वेषकहरू",
    label: "Research Team",
  },
  {
    valueEng: "UK Researcher Advisors",
    valueNep: "बेलायतका अन्वेषक सल्लाहकारहरू",
    label: "UK Researcher Advisors",
  },
  {
    valueEng: "Collaborators In Nepal",
    valueNep: "नेपालका सहयोगीहरू",
    label: "Collaborators In Nepal",
  },
  {
    valueEng: "International Collaborators",
    valueNep: "अन्तर्राष्ट्रिय सहयोगीहरू",
    label: "International Collaborators",
  },
];

const EditTeamMemberPage = (props) => {
  const { navigation, params } = useNavigation();
  const { toast } = useAuth();
  const { navigate, routes } = navigation;
  const {
    editTeamMemberAction,
    teamMember,
    getTeamMemberDetailAction,
    getResearchProgramAction,
    researchProgram,
  } = props;
  const { editLoader, detail } = teamMember;
  const { listResearchPorgram } = researchProgram;
  const { handleSubmit, register, control } = useForm();

  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    getTeamMemberDetailAction(Number(params.id));
  }, [getTeamMemberDetailAction, params.id]);

  const onChange = (imageList) => {
    setImageError(false);
    setImage(imageList);
  };

  const [researchProgramOption, setResearchProgramOption] = useState([]);
  const [selectedResearchProgram, setSelectedResearchProgram] = useState([]);
  const [deletedResearchProgramList, setDeletedResearchProgramList] = useState(
    [],
  );

  const handleResearchProgram = (selectedOptions) => {
    setSelectedResearchProgram(selectedOptions);

    const olderResearchProgramValue = detail?.research_program?.map(
      (item, index) => {
        return {
          id: item.id,
          label: item.name.en,
          value: item.name.en,
        };
      },
    );

    const removedResearchProgramId = olderResearchProgramValue?.filter(
      (oldItem) =>
        !selectedOptions?.some((newItem) => newItem.id === oldItem.id),
    );

    setDeletedResearchProgramList((prevItems) => {
      if (removedResearchProgramId?.length > 0) {
        return [...prevItems, ...removedResearchProgramId];
      } else {
        return [...prevItems];
      }
    });
  };

  useEffect(() => {
    const defaultResearchProgramValue = detail?.research_program?.map(
      (item, index) => {
        return {
          id: item.id,
          label: item.name.en,
          value: item.name.en,
        };
      },
    );

    setSelectedResearchProgram(defaultResearchProgramValue);
  }, [detail]);

  useEffect(() => {
    const remappedResearchProgramList = listResearchPorgram?.map(
      (item, index) => {
        return {
          id: item.id,
          label: item.name.en,
          value: item.name.en,
        };
      },
    );
    setResearchProgramOption(remappedResearchProgramList);
  }, [listResearchPorgram]);

  useEffect(() => {
    getResearchProgramAction();
  }, [getResearchProgramAction]);

  const onSubmit = (data) => {
    const catchedErros = {};
    const validate = validator(catchedErros);

    const description = {
      name: {
        en: data.nameEng,
        np: data.nameEng,
      },
      designation: {
        en: data.designationEng,
        np: data.designationNep,
      },
      address: {
        en: data.addressEng,
        np: data.addressNep,
      },
      role: detail.description?.role,
    };

    if (!!data.role?.valueEng) {
      description["role"] = {
        en: data.role.valueEng,
        np: data.role.valueNep,
      };
    }

    validate(
      "selectedResearchProgram",

      selectedResearchProgram === undefined ||
        selectedResearchProgram === null ||
        selectedResearchProgram?.length === 0,
      () => {
        toast({
          message: "Please Choose at least one research program",
          type: "error",
        });
      },
    );

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const body = {
      name: data.nameEng,
      designation: data.designationEng,
      description: description,
      address: data.addressEng,
      role: detail.description?.role.valueNep,
      bio: {
        en: data.bioEng,
        np: data.bioNep,
      },
      type: "team",
      addedResearch: selectedResearchProgram?.map((item, index) => item.id),
      deletedResearch: deletedResearchProgramList?.map(
        (item, index) => item.id,
      ),
    };

    if (!!data.role?.valueEng) {
      body["role"] = data.role?.valueEng;
    }

    const photoFormdata = new FormData();

    image.length > 0 && photoFormdata.append("imageFile", image[0].file);

    photoFormdata.append("type", "team");

    editTeamMemberAction(Number(params.id), body, image, goBackHandler, toast);
  };

  const goBackHandler = () => {
    navigate(routes["Team Member"].path);
  };

  return (
    <CompWrapper>
      {!detail ? (
        <DotPulse />
      ) : (
        <div className="addnews-container">
          <div className="addnews">
            <form className="addnews-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Name</div>

                <InputField
                  name="nameEng"
                  placeholder="Name"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.name?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Designation</div>

                <InputField
                  name="designationEng"
                  placeholder="Designation"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.designation?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Role/भूमिका</div>

                <Controller
                  as={
                    <Select
                      isClearable={true}
                      getOptionValue={(option) => `${option.valueEng}`}
                      options={options}
                      styles={selectStyles}
                      placeholder="Select Role"
                    />
                  }
                  name="role"
                  control={control}
                  defaultValue={options.filter(
                    (obj) => obj.valueEng === detail?.description?.role?.en,
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Address</div>

                <InputField
                  name="addressEng"
                  placeholder="Address"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.address?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Bio</div>

                <TextAreaField
                  name="bioEng"
                  placeholder="Your Bio ..."
                  innerRef={register({ required: true })}
                  defaultValue={detail?.bio?.en}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">नाम</div>

                <InputField
                  name="nameNep"
                  placeholder="नाम"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.name?.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">पद नाम</div>

                <InputField
                  name="designationNep"
                  placeholder="पद नाम"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.designation?.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">ठेगाना</div>

                <InputField
                  name="addressNep"
                  placeholder="ठेगाना"
                  innerRef={register({ required: true })}
                  defaultValue={detail?.description?.address?.np}
                />
              </div>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">बायो</div>

                <TextAreaField
                  name="bioNep"
                  placeholder="तपाईको बायो..."
                  innerRef={register({ required: true })}
                  defaultValue={detail?.bio?.np}
                />
              </div>

              {detail.image && image.length === 0 && (
                <div
                  style={{
                    position: "relative",
                    width: 200,
                    height: 200,
                    overflow: "hidden",
                  }}>
                  <img
                    src={FILE_URL + detail.image}
                    alt=""
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </div>
              )}

              <ImageUploading
                value={image}
                onChange={onChange}
                multiple={false}>
                {({ imageList, onImageUpload, onImageRemove }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}>
                      {imageList.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            width: 200,
                            height: 200,
                            overflow: "hidden",
                          }}>
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      className="fixedwidth"
                      onClick={onImageUpload}
                      variant="contained"
                      color="primary">
                      Change Image
                    </Button>
                    {imageError && <div>Image Required</div>}
                  </div>
                )}
              </ImageUploading>

              <div className="addnews-form-input">
                <div className="addnews-form-input-label">Research Program</div>
                <Select
                  isMulti
                  options={researchProgramOption}
                  value={selectedResearchProgram}
                  onChange={handleResearchProgram}
                  placeholder="Select Research Program"
                />
              </div>

              <ActivityIndicator animating={editLoader}>
                <Button
                  className="fixedwidth"
                  variant="contained"
                  color="primary"
                  type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    teamMember: state.teamMember,
    researchProgram: state.researchProgram,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editTeamMemberAction,
      getTeamMemberDetailAction,
      getResearchProgramAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamMemberPage);
