import {
  COLLABORATORS,
  ADD_COLLABORATORS,
  EDIT_COLLABORATORS,
  COLLABORATORS_DETAIL,
  DELETE_COLLABORATORS,
} from "./Actions";
import { api, APIS, TABLE_LIMIT } from "../config/Config";

export function getCollaboratorsAction(page, researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: COLLABORATORS.LOADING });

      if (researchProgramId) {
        res = await api(
          `${APIS.partner}&researchProgramId=${researchProgramId}&page=${page}&limit=${TABLE_LIMIT}`,
        );
      } else {
        res = await api(`${APIS.partner}&page=${page}&limit=${TABLE_LIMIT}`);
      }
      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: COLLABORATORS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: COLLABORATORS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: COLLABORATORS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getCollaboratorDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: COLLABORATORS_DETAIL.LOADING });
      res = await api(`${APIS.team}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: COLLABORATORS_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: COLLABORATORS_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: COLLABORATORS_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postCollaboratorAction(formdata, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_COLLABORATORS.LOADING });
      res = await api(APIS.createTeam, "POST", formdata, { file: true });

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_COLLABORATORS.SUCCESS });
        toast({ message: "Collaborator successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_COLLABORATORS.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_COLLABORATORS.ERROR });
      console.error(message);
      toast({ message: "Error Adding Collaborator", type: "error" });
      return 0;
    }
  };
}

export function editCollaboratorAction(id, body, image, goBackHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_COLLABORATORS.LOADING });
      res = await api(`${APIS.createTeam}/${id}`, "PATCH", body);
      const { success, data } = res.data;

      if (success === "true") {
        if (image.length > 0) {
          const photoFormdata = new FormData();

          photoFormdata.append("type", "partner");
          photoFormdata.append("imageFile", image[0].file);

          let photoRes = await api(
            `${APIS.team}/image/${id}`,
            "PATCH",
            photoFormdata,
            { file: true },
          );
          const { success: photoSuccess, data: photoData } = photoRes.data;
          if (photoSuccess === "true") {
            dispatch({ type: EDIT_COLLABORATORS.SUCCESS });
            toast({
              message: "Collaborator successfully edited",
              type: "success",
            });
            goBackHandler();
          } else {
            dispatch({ type: EDIT_COLLABORATORS.ERROR });
            toast({ message: photoData.message, type: "error" });
          }
        } else {
          dispatch({ type: EDIT_COLLABORATORS.SUCCESS });
          toast({
            message: "Collaborator successfully edited",
            type: "success",
          });
          goBackHandler();
        }
      } else {
        dispatch({ type: EDIT_COLLABORATORS.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_COLLABORATORS.ERROR });
      console.error(message);
      toast({ message: "Error editing Collaborator", type: "error" });
      return 0;
    }
  };
}

export function deleteCollaboratorAction(id, toast, page) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_COLLABORATORS.LOADING });
      res = await api(`${APIS.team}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_COLLABORATORS.SUCCESS });
        toast({
          message: "Collaborator successfully deleted",
          type: "success",
        });
        dispatch(getCollaboratorsAction(page));
      } else {
        dispatch({ type: DELETE_COLLABORATORS.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_COLLABORATORS.ERROR });
      toast({ message: "Error Deleting Collaborator", type: "error" });
      console.error(message);
      return 0;
    }
  };
}
