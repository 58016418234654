import React from "react";
const DotPulse = () => {
  return (
    <div className="stage">
      <div className="dot-pulse">loading.....</div>
    </div>
  );
};

export default DotPulse;
